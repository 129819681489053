<template>
    <div class="viewOne">

        <div class="banner">
            <navigationBar :activeName="1"></navigationBar>
           
            <div class="bannerbody">
                <div class="left">
                    <div class="title1"><span>Servicio rápido</span> de préstamo de efectivo</div>
                    <div class="title2">Puede obtener un préstamo de 1 millón a 2 millones
                        de pesos, y llegará a la cuenta en 3 minutos como máximo. </div>
                    <div class="title3">
                        <img @click="navShop(1)" src="../assets/img/GooglePlay.png" class="img">
                        <!-- <img src="../assets/img/AppStore.png" class="img"> -->
                    </div>
                    <div class="title4">
                        <img src="../assets/img/4048.png" class="img">
                        1M+ Pedir prestado dinero con éxito
                    </div>
                </div>
                <img src="../assets/img/11134.png" class="right">
            </div>
        </div>
        <div class="div1">
            <div class="title1">Préstamo rápido en 3 pasos</div>
            <img src="../assets/img/Union2.png" class="fixedimg2">
            <div class="items">
                <div class="item1">
                    <img src="../assets/img/11099.png" class="img1">
                    <div class="title3">Información perfecta</div>
                    <div class="title4">Cargue su tarjeta de identificación y mejore la información personal</div>
                </div>
                <img src="../assets/img/Union.png" class="img2">
                <div class="item1">
                    <img src="../assets/img/11100.png" class="img1">
                    <div class="title3">Espera la revisión</div>
                    <div class="title4">Revisaremos su información dentro de las 12 horas</div>
                </div>
                <img src="../assets/img/Union.png" class="img2">
                <div class="item1">
                    <img src="../assets/img/11101.png" class="img1">
                    <div class="title3">Reciba el préstamo</div>
                    <div class="title4">Deposite en su cuenta bancaria o billetera electrónica</div>
                </div>
            </div>
            <div class="textimg">
                <img src="../assets/img/14377.png" class="img">
                <div class="text">
                    <div class="text1">
                        Rápido, conveniente y fácil de pagar
                    </div>
                    <div class="text2">
                        Solo necesita llenar un formulario, mostrar tu DNI, y puede
                        obtener un préstamo de hasta $2,000,000 pesos btener un préstamo de hasta.
                    </div>
                </div>

            </div>
            <div class="textimg">

                <div class="text">
                    <div class="text1">
                        ¡Pide tu préstamo por hasta $2,500,000 en minutos!
                    </div>
                    <div class="text2">
                        Necesita un préstamo? Abre la APP Préstamo Rápido y solicita el
                        dinero que necesites btener un préstamo de hasta $2,000,000 pesos.
                    </div>
                </div>
                <img src="../assets/img/14378.png" class="img">
            </div>
            <div class="bgbule">
                <div class="bgbulebody">
                    <div class="text">
                        Muchas líneas de créditos y muchos periodos de pago para elegir
                    </div>
                    <div class="check">
                        <span> <img src="../assets/img/check.png" class="checkimg">Sin garantía</span>
                        <span> <img src="../assets/img/check.png" class="checkimg">Sin comisión</span>
                        <span> <img src="../assets/img/check.png" class="checkimg">Intereses bajos</span>
                    </div>
                    <div class="icon icon1">
                        <img src="../assets/img/Icon1.png" class="iconimg">
                        <div class="icontext">
                            <div class="icontext1">$2,500,000</div>
                            <div class="icontext2">Préstamo máxima</div>
                        </div>
                    </div>
                    <div class="icon icon2">
                        <img src="../assets/img/Icon2.png" class="iconimg">
                        <div class="icontext">
                            <div class="icontext1">30 días</div>
                            <div class="icontext2">Plazo máximo</div>
                        </div>
                    </div>
                    <div class="icon icon3">
                        <img src="../assets/img/Icon3.png" class="iconimg">
                        <div class="icontext">
                            <div class="icontext1">15 min</div>
                            <div class="icontext2">Llegada más rápida</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="div2">
            <div class="title">Testimonios de usuarios</div>
            <div class="title1">Préstamo Rápido ofrece servicios crediticios para ciudadanos colombianos de 18 a 55 años, a
                través del control automático de riesgos, si el prestatario</div>
            <el-carousel ref="carousel" height="500px" indicator-position="none" arrow="never">
                <el-carousel-item key="1">
                    <div class="lbt">
                        <img src="../assets/img/11117.png" class="leftimg">
                        <div class="rightdiv">
                            <img src="../assets/img/Union3.png" class="unionimg">
                            <div class="text1">Soy un cliente habitual de esta aplicación. He solicitado un préstamo tres veces y todo ha ido bien. Es realmente conveniente tenerlo en la vida. ¡Muchas gracias!</div>
                            <img src="../assets/img/62.png" class="xingxing">
                            <div class="text2">
                                Nataniel Sanz
                            </div>
                            <div class="text3">
                                <div>Estudiante</div>
                                <div>
                                    <img src="../assets/img/jiantou.png" style="transform: rotate(180deg);"
                                        @click="nextprev()" class="jiantou">
                                    <img src="../assets/img/jiantou.png" class="jiantou" @click="nextnext()">
                                </div>
                            </div>

                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item key="2">
                    <div class="lbt">
                        <img src="../assets/img/11118.png" class="leftimg">
                        <div class="rightdiv">
                            <img src="../assets/img/Union3.png" class="unionimg">
                            <div class="text1">Es genial. Me lo recomendó un amigo. Lo he pedido prestado 3 veces. Me ofrecieron varios montos de préstamo para elegir.</div>
                            <img src="../assets/img/62.png" class="xingxing">
                            <div class="text2">
                                Liliana Alvarez
                            </div>
                            <div class="text3">
                                <div>Estudiante</div>
                                <div>
                                    <img src="../assets/img/jiantou.png" style="transform: rotate(180deg);"
                                        @click="nextprev()" class="jiantou">
                                    <img src="../assets/img/jiantou.png" class="jiantou" @click="nextnext()">
                                </div>
                            </div>

                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item key="3">
                    <div class="lbt">
                        <img src="../assets/img/111191.png" class="leftimg">
                        <div class="rightdiv">
                            <img src="../assets/img/Union3.png" class="unionimg">
                            <div class="text1">La velocidad del préstamo es muy rápida, lo cual es muy bueno para personas en situaciones de emergencia.</div>
                            <img src="../assets/img/62.png" class="xingxing">
                            <div class="text2">
                                Timo Caballero  
                            </div>
                            <div class="text3">
                                <div>Cuello blanco</div>
                                <div>
                                    <img src="../assets/img/jiantou.png" style="transform: rotate(180deg);"
                                        @click="nextprev()" class="jiantou">
                                    <img src="../assets/img/jiantou.png" class="jiantou" @click="nextnext()">
                                </div>
                            </div>

                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item key="4">
                    <div class="lbt">
                        <img src="../assets/img/11120.png" class="leftimg">
                        <div class="rightdiv">
                            <img src="../assets/img/Union3.png" class="unionimg">
                            <div class="text1">Debo darle 5 estrellas. Esta es la mejor aplicación que he usado. Tiene una velocidad de préstamo rápida y un límite de crédito alto.</div>
                            <img src="../assets/img/62.png" class="xingxing">
                            <div class="text2">
                                Brisia Aviles
                            </div>
                            <div class="text3">
                                <div>Lanza libre</div>
                                <div>
                                    <img src="../assets/img/jiantou.png" style="transform: rotate(180deg);"
                                        @click="nextprev()" class="jiantou">
                                    <img src="../assets/img/jiantou.png" class="jiantou" @click="nextnext()">
                                </div>
                            </div>

                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item key="5">
                    <div class="lbt">
                        <img src="../assets/img/11121.png" class="leftimg">
                        <div class="rightdiv">
                            <img src="../assets/img/Union3.png" class="unionimg">
                            <div class="text1">El monto del préstamo es alto y solo necesita su tarjeta de identificación para solicitarlo, lo cual es muy conveniente. El monto del préstamo es alto y solo necesita de cual es muy conveniente. </div>
                            <img src="../assets/img/62.png" class="xingxing">
                            <div class="text2">
                                Gilberto Cedano
                            </div>
                            <div class="text3">
                                <div>Venta</div>
                                <div>
                                    <img src="../assets/img/jiantou.png" style="transform: rotate(180deg);"
                                        @click="nextprev()" class="jiantou">
                                    <img src="../assets/img/jiantou.png" class="jiantou" @click="nextnext()">
                                </div>
                            </div>

                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>

        </div>
    </div>
</template>
  
<script>
import OSInfo from '@/utils/OSInfo'
import navigationBar from '@/components/navigationBar.vue'
export default {
    name: 'viewOne',
    components: {
        navigationBar,

    },
    data() {
        return {
            activeName: 1,
            value: 50,
            day: 6,
            operatingSystem: '',

        }
    },

    created() {
        this.init()
    },
    computed: {
        amountFormato(s) {

            let e = (50000 + (2950000 * (this.value / 100))).toFixed(0)
            return String(e).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    },
    methods: {
        async init() {
            this.operatingSystem = await OSInfo.getOSInfo()
        },
        sliderChange(e) {
            this.value = e
        },
        dayClick(e) {
            this.day = e
        },
        navShop(e) {
            if (e == 1) {
                //window.open('http://confirm.prestamorapido.co/', '_blank')
                window.location.href = "https://play.google.com/store/apps/details?id=com.prestamo&hl=es-419&gl=co"
            } else {
                window.open('http://confirm.prestamorapido.co/', '_blank')
               // window.location.href = "http://t.copii.co/1hVVVn"
            }
        },
        goShop() {
            if (this.operatingSystem.includes('windows') || this.operatingSystem.includes('Windows')) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.prestamo&hl=es-419&gl=co"
            } else {
                window.open('http://confirm.prestamorapido.co/', '_blank')
            }
        },
        nextprev() {
            this.$refs.carousel.prev()
        },
        nextnext() {
            this.$refs.carousel.next()
        }
    }
}
</script>

<style scoped lang="scss">
navigationBar {
    background-color: transparent;
}

.banner {
    width: 100%;
    min-width: 1200px;
    height: 800px;
    background: url(../assets/img/11098.png) no-repeat top;
    background-size: cover;
    position: relative;

    .bannerbody {
        padding-top: 96px;
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .left {
            width: 524px;
            text-align: left;

            .title1 {

                font-family: Inter, Inter;
                font-weight: bold;
                font-size: 49px;
                color: #282C33;
                line-height: 60px;

                span {
                    color: #6765E8;
                }
            }

            .title2 {
                font-weight: 500;
                font-size: 17px;
                color: #575A60;
                margin-top: 28px;
            }

            .title3 {
                margin-top: 38px;

                .img {
                    cursor: pointer;
                    border-radius: 15px;
                    width: 198px;
                    height: 66px;
                    margin-right: 14px;
                }
            }

            .title4 {
                margin-top: 32px;
                display: flex;
                align-items: center;

                .img {
                    width: 149px;
                    height: 47px;
                    margin-right: 11px;
                }

                font-weight: 500;
                font-size: 17px;
                color: #1E0140;

            }
        }

        .right {
            width: 484px;
            height: 501px;
        }
    }

}

.div1 {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 100px;
    position: relative;

    .title1 {
        margin-top: 130px;
        font-weight: bold;
        font-size: 40px;
        color: #282C33;
        margin-bottom: 66px;
    }

    .items {
        display: flex;
        justify-content: center;
        margin-bottom: 60px;

        .item1 {
            width: 280px;

            .img1 {
                width: 142px;
                height: 142px;
            }

            .title3 {
                margin-top: 24px;
                font-weight: normal;
                font-size: 25px;
                color: #282C33;
            }

            .title4 {
                margin-top: 9px;
                font-weight: 400;
                font-size: 17px;
                color: #575A60;
                line-height: 28px;
            }
        }

        .img2 {
            height: 18px;
            width: 83px;
            margin: 71px 14px 0 14px;
        }
    }

    .textimg {
        margin-top: 80px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .img {
            width: 555px;
            height: 400px;
        }

        .text {
            text-align: left;
            width: 434px;

            .text1 {
                font-weight: bold;
                font-size: 40px;
                color: #282C33;
                line-height: 57px;
            }

            .text2 {
                margin-top: 28px;
                font-weight: 500;
                font-size: 17px;
                color: #575A60;
                line-height: 28px;
            }
        }
    }

    .bgbule {
        margin-top: 150px;
        width: 100%;
        height: 402px;
        background: url(../assets/img/11070.png) no-repeat top;
        background-size: 100% 100%;
        position: relative;
        border-radius: 28px;
        display: flex;
        align-items: center;
        margin-bottom: 37px;

        .bgbulebody {
            height: 300px;
            margin-left: 70px;
            flex: 1;
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;

            .text {
                width: 522px;
                font-weight: bold;
                font-size: 40px;
                color: #FFFFFF;
                line-height: 57px;
                text-align: left;
            }

            .check {
                margin-top: 30px;
                width: 522px;
                line-height: 23px;
                font-weight: 500;
                display: flex;
                align-items: center;
                color: #FFFFFF;

                span {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                }

                .checkimg {
                    width: 23px;
                    height: 23px;
                    margin-right: 8px;
                }
            }

            .icon1 {
                position: absolute;
                right: 201px;
                top: 0;
            }

            .icon2 {
                position: absolute;
                right: 69px;
                top: 94px;
            }

            .icon3 {
                position: absolute;
                right: 140px;
                top: 188px;
            }

            .icon {
                width: 217px;
                height: 76px;
                background: #FFFFFF;
                border-radius: 8px 8px 8px 8px;
                display: flex;
                align-items: center;

                .iconimg {
                    width: 42px;
                    height: 42px;
                    margin: 0 18px;
                }

                .icontext {
                    text-align: left;

                    .icontext1 {
                        font-weight: bold;
                        font-size: 19px;
                        color: #37393F;
                        margin-bottom: 4px;
                    }

                    .icontext2 {
                        font-weight: 400;
                        font-size: 13px;
                        color: #707070;
                    }
                }

            }
        }
    }

}

.div2 {
    width: 1200px;
    margin: 0 auto;

    .title {
        font-weight: bold;
        font-size: 40px;
        color: #282C33;
    }

    .title1 {
        max-width: 720px;
        margin: 0 auto;
        margin-top: 28px;
        font-size: 17px;
        color: #575A60;
        line-height: 28px;
        text-align: center;
    }

    .lbt {
        margin: 66px auto;
        height: 397px;
        display: flex;
        background: #FFFFFF;
        // box-shadow: 0px 14 47px 0px rgba(53,51,186,0.07);
        box-shadow: 0px 0px 20px rgba(53, 51, 186, 0.07);
        border-radius: 28px 28px 28px 28px;

        .leftimg {
            width: 312px;
            height: 397px;
            border-radius: 28px;
        }

        .rightdiv {
            max-width: 595px;
            display: flex;
            flex-direction: column;
            align-items: left;
            margin-left: 94px;
            text-align: left;
            justify-content: center;

            .unionimg {
                width: 41px;
                height: 30px;
            }

            .text1 {

                margin-top: 18px;
                font-weight: 400;
                font-size: 17px;
                color: #282C33;
                line-height: 28px;

                font-style: normal;
            }

            .xingxing {
                width: 81px;
                height: 13px;
                margin-top: 26px;
            }

            .text2 {
                font-weight: normal;
                margin-top: 21px;
                font-size: 23px;
                color: #575A60;
            }

            .text3 {
                font-weight: 400;
                font-size: 17px;
                color: #575A60;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .jiantou {
                    cursor: pointer;
                    width: 42px;
                    height: 42px;
                    margin-left: 28px;
                }
            }

        }
    }

}

.fixedimg1 {
    position: absolute;
    right: -50px;
    top: 840px;
    width: 138px;
    height: 138px;
}

.fixedimg2 {
    position: absolute;
    left: -37px;
    bottom: -37px;

    width: 138px;
    height: 138px;

}
</style>
  