import { createRouter, createWebHistory } from 'vue-router'
import viewOne from '../views/viewOne.vue'
import viewTwo from '../views/viewTwo.vue'
import viewThree from '../views/viewThree.vue'
import viewFour from '../views/viewFour.vue'
import viewFive from '../views/viewFive.vue'
import privacidad from '../views/privacidad.vue'
import terms from '../views/terms.vue'
const routes = [
  {
    path: '/',
    name: 'viewOne',
    component: viewOne
  },
  {
    path: '/Producto',
    name: 'Producto',
    component: viewTwo
  },
  {
    path: '/Ayuda',
    name: 'Ayuda',
    component: viewThree
  },
  {
    path: '/SobreNosotros',
    name: 'SobreNosotros',
    component: viewFour
  },
  {
    path: '/Contáctenos',
    name: 'Contáctenos',
    component: viewFive
  },
  
  {
    path: '/privacidad',
    name: 'privacidad',
    component: privacidad
  },

  {
    path: '/term',
    name: 'term',
    component: terms
  }
  

]

const router = createRouter({
  model:'Hash',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
