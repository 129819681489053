<template>
    <div class="contnet">
        <navigationBar :activeName="4"></navigationBar>
        <img src="../assets/img/11128-1.png" class="topimg">
        <div class="title1">
            Préstamo Rápido es una plataforma que ofrece préstamos en línea rápidos, accesibles y seguros. Regístrate en
            segundos, aplica para un préstamo y recibe tu efectivo en minutos. Vemos el mundo diferente. Estamos motivados
            por una creencia fundamental en las personas, y trabajamos juntos para demostrar su potencial. Asumimos riesgos
            que otros no tomarían para poner más poder en las manos de nuestros clientes. Préstamo Rápido
            está operando por la empresa TTEM,
            LLC, que se ha arraigado profundamente en la industria financiera por muchos años y tiene una rica experiencia en prestar servicios de crédito. La compañía brinda diversos servicios financieros en muchas regiones de América Latina. Su excelente conocimiento profesional y experiencia de servicio han ganado muchos elogios de los clientes de América Latina. Continuaremos trabajando y esforzándonos para mejorar su experiencia crediticia, con el objetivo que hacer que más personas puedan disfrutar de la inclusión financiera.
        </div>
        <div class="img-text">
            <div class="text">
                <div class="text1">Servicio rápido de préstamo de efectivo</div>
                <div class="text2">Cimentada bajo el estricto cumplimiento de una política bien definida en conocer a nuestros clientes, ofreciéndoles una atención personalizada en sus transacciones financieras; brindándoles además, un servicio excelente y altamente eficiente utilizando los más avanzados recursos tecnológicos de una banca moderna. Somos un banco a la vanguardia del desarrollo económico de la República de Panamá.</div>
            </div>
            <img src="../assets/img/11128-2.png" class="img">
        </div>
        <div class="img-text">
            <img src="../assets/img/11128-3.png" class="img">
            <div class="text">
                <div class="text1">Servicio al cliente de alta calidad, confiable y confiable</div>
                <div class="text2">Cimentada bajo el estricto cumplimiento de una política bien definida en conocer a nuestros clientes, ofreciéndoles una atención personalizada en sus transacciones financieras; brindándoles además, un servicio excelente y altamente eficiente utilizando los más avanzados recursos tecnológicos de una banca moderna. </div>
            </div>
           
        </div>
    </div>
</template>
  
<script>
import navigationBar from '@/components/navigationBar.vue'
export default {
    components: {
        navigationBar,

    },
    data() {
        return {

        }
    },
    created() {
    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
.contnet {
    .topimg {
        min-width: 1200px;
        width: 100%;
    }

    .title1 {
        text-align: left;
        word-wrap: break-word;
        width: 1100px;
        margin: 128px auto;
        font-weight: 400;
        font-size: 17px;
        color: #575A60;
        line-height: 30px;
    }
    .img-text{

        width: 1200px;
        margin: 0 auto;
        margin-bottom: 128px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .img{
            width: 518px;
            height: 375px;
        }
        .text{
            width: 474px;
            .text1{
                font-size: 40px;
color: #282C33;
line-height: 53px;
text-align: left;
            }
            .text2{
                font-weight: 400;
                margin-top: 28px;
font-size: 17px;
color: #282C33;
line-height: 30px;
text-align: left;
font-style: normal;
            }
        }
    }
}</style>
  