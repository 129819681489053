<template>
    <div class="viewOne">

        <div class="banner">
            <navigationBar :activeName="2"></navigationBar>
            <div class="bannerbody">
                <div class="left">
                    <div class="title1">Préstamos en línea</div>
                    <div class="title2">Préstamo Rápido brinda servicios de préstamos financieros con rotación de capital
                        las 24 horas del día, los usuarios pueden utilizar la aplicación para solicitar préstamos en
                        cualquier momento y en cualquier lugar. El plazo máximo del préstamo es de 180 días y el préstamo
                        puede desembolsarse en 5 minutos.</div>
                    <div class="title3">
                        <img src="../assets/img/GooglePlay.png" @click="navShop(1)" class="img">
                        <!-- <img src="../assets/img/AppStore.png" class="img"> -->
                    </div>
                </div>
                <img src="../assets/img/11074.png" class="right">
            </div>
        </div>
        <div class="video">
            <div class="title">Solicita un préstamo y se acreditará de inmediato</div>
            <video controls>
                <source src="../assets/video.mp4" type="video/mp4">
                Your browser does not support playing videos.
            </video>

        </div>
        <div class="items">
            <div class="title1">Ventajas de nuestros productos</div>
            <div class="title2">Primera opción para la rotación de fondos, desembolso menos de 3 min con alto crédito</div>
            <div class="content">
                <div class="item" v-for="(i, index) in item" :key="index">
                    <img :src="i.img" class="img">
                    <div class="text1">{{ i.text1 }}</div>
                    <div class="text2">{{ i.text2 }}</div>
                </div>
            </div>
        </div>
        <div class="items">
            <div class="title1">Condición de préstamo</div>
            <div class="title2">Préstamo Rápido brinda facilidades de crédito a ciudadanos colombianos entre 18 y 55 años, si el prestatario Pagar el préstamo a tiempo y aumentar el monto cuando se solicite nuevamente</div>
            <div class="content2">
                <div class="item">
                    <img src="../assets/img/a11120.png" class="right">
                    <div>Tarjeta de identificación emitida por el gobierno colombiano</div>
                </div>
                <div class="item">
                    <img src="../assets/img/a11121.png" class="right">
                    <div>Número de teléfono disponible en Colombia</div>
                </div>
                <div class="item">
                    <img src="../assets/img/a11122.png" class="right">
                    <div>Tarjeta bancaria aceptable o billetera electrónica</div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import OSInfo from '@/utils/OSInfo'
import navigationBar from '@/components/navigationBar.vue'
export default {
    name: 'viewOne',
    components: {
        navigationBar,

    },
    data() {
        return {
            activeName: 1,
            value: 50,
            day: 6,
            operatingSystem: '',

            item: [
                {
                    img: require('@/assets/img/Icons.png'),
                    text1: 'Llegada rápida',
                    text2: 'Primera opción para la rotación de fondos, desembolso menos de 3 min con alto crédito',
                },
                {
                    img: require('@/assets/img/Icons1.png'),
                    text1: 'Intereses bajos',
                    text2: 'Intereses tan bajos como 0.05% por día, sin cargos adicionales',
                }, {
                    img: require('@/assets/img/Icons2.png'),
                    text1: 'Amortización diferida',
                    text2: 'El plazo del préstamo es largo y el pago se puede diferir hasta por 12 días',
                }, {
                    img: require('@/assets/img/Icons3.png'),
                    text1: 'Proceso sencillo',
                    text2: 'Solo se necesitan tres sencillos pasos para solicitar un préstamo, 100% online',
                }, {
                    img: require('@/assets/img/Icons4.png'),
                    text1: 'Cantidades más altas',
                    text2: 'Obtener una cantidad más alta después del primer préstamo exitoso',
                }, {
                    img: require('@/assets/img/Icons5.png'),
                    text1: 'Pago conveniente',
                    text2: 'Deposite directamente en una billetera electrónica o tarjeta bancaria',
                }
            ]

        }
    },

    created() {
       
    },
    computed: {
        amountFormato(s) {

            let e = (50000 + (2950000 * (this.value / 100))).toFixed(0)
            return String(e).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    },
    methods: {
       
        sliderChange(e) {
            this.value = e
        },
        dayClick(e) {
            this.day = e
        },
        navShop(e) {
            if (e == 1) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.prestamo&hl=es-419&gl=co"
            } else {
                window.open('http://confirm.prestamorapido.co/', '_blank')
            }
        },
        goShop() {
           
        },
        nextprev() {
            this.$refs.carousel.prev()
        },
        nextnext() {
            this.$refs.carousel.next()
        }
    }
}
</script>

<style scoped lang="scss">
navigationBar {
    background-color: transparent;
}

.banner {
    width: 100%;
    min-width: 1200px;
    height: 800px;
    background: url(../assets/img/11098.png) no-repeat top;
    background-size: cover;
    position: relative;

    .bannerbody {
        padding-top: 96px;
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .left {
            width: 474px;
            text-align: left;

            .title1 {

                font-weight: bold;
                font-size: 49px;
                color: #282C33;

            }

            .title2 {
                margin-top: 28px;
                font-weight: 500;
                font-size: 17px;
                color: #575A60;
                line-height: 28px;
                text-align: left;
            }

            .title3 {
                margin-top: 38px;

                .img {
                    border-radius: 15px;
                    width: 198px;
                    cursor: pointer;
                    height: 66px;
                    margin-right: 14px;
                }
            }


        }

        .right {
            width: 542px;
            height: 470px;
        }
    }

}

.video {
    width: 1200px;
    margin: 0 auto;

    .title {
        font-weight: bold;
        font-size: 40px;
        color: #282C33;
        text-align: center;
        margin-top: 134px;
        margin-bottom: 66px;
    }

    video {

        width: 1200px;
        margin: 0 auto;
        border-radius: 28px;
        margin-bottom: 50px;
    }
}

.items {
    width: 1200px;
    margin: 0 auto;

    .title1 {
        font-weight: bold;
        font-size: 40px;
        color: #282C33;
        margin-top: 85px;
        text-align: center;

    }

    .title2 {
        line-height: 28px;
        width: 795px;
        margin: 0 auto;
        text-align: center;
        font-weight: 500;
        font-size: 17px;
        color: #575A60;
        margin-top: 28px;
        text-align: center;
    }

    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 20px;
        margin-bottom: 40px;

        .item {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 331px;
            height: 382px;
            background: #FFFFFF;
            box-shadow: 4px 9px 38px 0px rgba(98, 98, 146, 0.07);
            border-radius: 28px 28px 28px 28px;

            .img {
                width: 113px;
                height: 113px;
            }

            .text1 {
                margin-top: 28px;
                font-weight: normal;
                font-size: 25px;
                color: #282C33;
            }

            .text2 {
                margin-top: 14px;
                width: 255px;
                font-weight: 400;
                font-size: 17px;
                color: #575A60;
                line-height: 28px;
                text-align: center;
            }
        }
    }
    .content2{
        margin-top: 66px;
        margin-bottom: 150px;
      display: flex;
      justify-content: space-around;
      .item{
        width: 321px;
        img{
            width: 321px;
            height: 302px;
        }
        font-weight: 400;
        font-size: 17px;
        color: #575A60;
        line-height: 28px;
        text-align: left;
      }
       
    }
}</style>
  