const navigator = window.navigator
// 获取操作系统
function getUserOsInfo() {
  const userAgent = navigator.userAgent
  if (userAgent.indexOf('Windows NT 10.0') !== -1) return 'Windows 10'
  if (userAgent.indexOf('Windows NT 6.2') !== -1) return 'Windows 8'
  if (userAgent.indexOf('Windows NT 6.1') !== -1) return 'Windows 7'
  if (userAgent.indexOf('Windows NT 6.0') !== -1) return 'Windows Vista'
  if (userAgent.indexOf('Windows NT 5.1') !== -1) return 'Windows XP'
  if (userAgent.indexOf('Windows NT 5.0') !== -1) return 'Windows 2000'
  if (userAgent.indexOf('Mac') !== -1) return 'Mac/iOS'
  if (userAgent.indexOf('X11') !== -1) return 'UNIX'
  if (userAgent.indexOf('Linux') !== -1) return 'Linux'
  return 'Other'
}
async function getOSInfo() {
  var operatingSystem = getUserOsInfo() // 返回操作系统
  if (operatingSystem === 'Windows 10') {
    try {
      
      const os = await navigator.userAgentData.getHighEntropyValues(['platformVersion'])
      .then(ua => {
        if (navigator.userAgentData.platform === 'Windows') {
          const majorPlatformVersion = parseInt(ua.platformVersion.split('.')[0])
          if (majorPlatformVersion >= 13) {
            return 'Windows 11'
          } else {
            return operatingSystem
          }
        } else {
          // console.log("Not running on Windows")
          return operatingSystem
        }
      })
      return os
    } catch (error) {
      return 'Windows 11'
    }
    
  } else {
    return operatingSystem
  }
}
const OSInfo = {
	getOSInfo: getOSInfo
}
export default OSInfo
