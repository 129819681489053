<template>
    <div class="contnet">
        <navigationBar :activeName="5"></navigationBar>
        <div class="title">ACTYSERVICIOS SAS<br />
            Contáctanos en cualquier momento</div>
        <div class="body">
            <img src="../assets/img/11141.png" class="left">
            <div class="right">
                <!-- <div class="item">
                    <img src="../assets/img/11107.png" class="img">
                    <div class="text">
                        <div class="text1">
                            Teléfono:
                        </div>
                        <div class="text2">
                            +57 312 663 7101
                        </div>
                    </div>
                </div> -->
                <div class="item">
                    <img src="../assets/img/11108.png" class="img">
                    <div class="text">
                        <div class="text1">
                            Email:
                        </div>
                        <div class="text2">
                            contacto1@prestamorapido.co
                        </div>
                    </div>
                </div>
                <div class="item">
                    <img src="../assets/img/11109.png" class="img">
                    <div class="text">
                        <div class="text1">
                            Dirección:
                        </div>
                        <div class="text2">
                            <!-- Lunes a Viernes de 9am a 6pm, Sábado a Domingo de 9am a 4pm -->
                            <!-- CL 19  5  22 ED BACATA OF 1105, Bogotá D.C. -->
                            <!-- Calle 19 5 22 Edificio BacataOficina 1105 -->
                            Dirección de la empresa: Calle 19 5 22 Edificio Bacata, oficina 1105, Bogotá D.C.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import navigationBar from '@/components/navigationBar.vue'
export default {
    components: {
        navigationBar,

    },
    data() {
        return {

        }
    },
    created() {
    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
.contnet {
    .title {
        font-weight: bold;
        font-size: 40px;
        color: #282C33;
        margin-top: 86px;
        margin-bottom: 66px;
    }

    .body {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 100px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        align-items: center;

        .left {
            width: 510px;
            height: 383px;
        }

        .right {
            height: 383px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .item {
                margin: 12px 0;
                display: flex;
                align-items: center;


                .img {
                    width: 77px;
                    height: 77px;
                    margin-right: 25px;
                }

                .text {
                    margin-top: 4px;
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    max-width: 369px;

                    .text1 {
                        font-weight: 400;
                        font-size: 23px;
                        color: #50545B;
                    }

                    .text2 {
                        font-weight: 500;
                        font-size: 23px;
                        color: #282C33;
                    }

                }
            }
        }
    }
}</style>
  