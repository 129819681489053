<template>
    <div class="contnet">
        <navigationBar :activeName="6"></navigationBar>
        <img src="../assets/img/11128-4.png" class="topimg">
        <iframe src="https://www.prestamorapido.co/privacy.html"></iframe>
    </div>
</template>
  
<script>
import navigationBar from '@/components/navigationBar.vue'
export default {
    components: {
        navigationBar,

    },
    data() {
        return {

        }
    },
    created() {
    },
    methods: {

    }
}
</script>

<style scoped lang="scss">
.contnet {
   
    .topimg {
        min-width: 1200px;
        width: 100%;
    }
    iframe{
        width: 1100px;
        margin: 0px auto;
        margin-top: 100px;
        border: none;
        height: 9400px;
    }

}
</style>
  