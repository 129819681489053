<template>
  <div id="app">
   <router-view/>
   <bottomBar></bottomBar>
   
  </div>
</template>
<script>
import navigationBar from '@/components/navigationBar.vue'
import bottomBar from '@/components/bottomBar.vue'
export default {
  name: 'App',
  components: {
    bottomBar
  }
}
</script>

<style lang="scss">
html{
  font-size: 12px;
}
*{
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
background-color: #fff;
min-width: 1200px;
}


</style>
