<template>
    <div class="bottomBar">
        <div class="body">
            <div class="top">
                <img src="../assets/logomax.png" class="left">
                <div class="right">
                    <img @click="navShop(1)" src="../assets/img/GooglePlay.png" class="img">
                    <!-- <img src="../assets/img/AppStore.png" class="img"> -->
                </div>
            </div>
            <div class="top2">
                <img src="../assets/img/facebook.png" class="img">
                <img src="../assets/img/Linkedin.png" class="img">
                <img src="../assets/img/youtube.png" class="img">
                <img src="../assets/img/instagram.png" class="img">
            </div>
            <div class="top3">
                <div class="right">
                    <div class="item">
                        <img src="../assets/11139.png" class="img">
                        <div class="text">
                            <div class="text1">
                                <!-- Copyright © PATHWAY SOLUTION SAS -->
                                <!-- Empresa: PATHWAY SOLUTION SAS -->
                                Nombre de empresa: ACTYSERVICIOS SAS
                            </div>

                        </div>
                    </div>
                    <div class="item">
                        <img src="../assets/img/11109.png" class="img">
                        <div class="text">
                            <div class="text1">

                                <!-- Lunes a Viernes de 9am a 6pm, Sábado a Domingo de 9am a 4pm -->
                                <!-- CL 19  5  22 ED BACATA OF 1105, Bogotá D.C. -->
                                <!-- Dirección: Calle 19 5 22 Edificio BacataOficina 1105 -->
                                Dirección de la empresa: Calle 19 5 22 Edificio Bacata, oficina 1105, Bogotá D.C.
                            </div>

                        </div>
                    </div>
                    <!-- <div class="item">
                        <img src="../assets/img/11107.png" class="img">
                        <div class="text">
                            <div class="text1">
                                Teléfono: +57 312 663 7101
                            </div>

                        </div>
                    </div> -->
                    <div class="item">
                        <img src="../assets/img/11108.png" class="img">
                        <div class="text">
                            <div class="text1">
                                Correo electrónico: contacto1@prestamorapido.co
                            </div>

                        </div>
                    </div>
                    <div class="item">
                        <img src="../assets/img/11107.png" class="img">
                        <div class="text">
                            <div class="text1">
                                Horario de atención: 9: 00-18: 00 (días hábiles)
                            </div>

                        </div>
                    </div>
                    <div class="item">
                        <img src="../assets/img/11109-1.png" class="img2">
                        <div class="text">
                            <div class="text1">
                                <a target="\_blank"
                                    href="https://www.prestamorapido.co/POLÍTICA_DE_TRATAMIENTO_DE_DATOS.html">POLÍTICA
                                    DE
                                    TRATAMIENTO DE DATOS</a>
                            </div>

                        </div>
                    </div>
                    <div class="item">
                        <img src="../assets/img/11109-1.png" class="img2">
                        <div class="text">
                            <div class="text1">
                                <a target="\_blank"
                                    href="https://www.prestamorapido.co/TÉRMINOS_Y_CONDICIONES.html">TÉRMINOS
                                    Y CONDICIONES</a>
                            </div>

                        </div>
                    </div>
                    <div class="item">
                        <img src="../assets/img/colombiaco_Mesa de trabajo_1.png" class="img">
                        <div class="text">
                            <div class="text1">
                                <a href="https://servicioslinea.sic.gov.co/servilinea/PQRSF/" target="\_blank"
                                    style="text-decoration: underline;">Super Intendencia Industria y
                                    Comercio</a>
                            </div>

                        </div>
                    </div>
                    <div class="item">
                        <img src="../assets/11138.png" class="img">
                        <div class="text">
                            <div class="text1">
                                Copyright© ACTYSERVICIOS SAS
                            </div>

                        </div>
                    </div>

                </div>
                <div class="left">

                </div>
            </div>
        </div>
        <div v-if="showCookieNotice" class="cookie-notice">
            Este sitio web utiliza cookies para asegurarse de que tiene la mejor experiencia en nuestro sitio Web.
            Seguir usando nuestro sitio web significa que está de acuerdo con que usemos cookies.
            <a class="cookie-a" target="\_blank" href="https://www.prestamorapido.co/cookies.html">《Aviso de
                Cookies》</a>
            <el-button size="mini" plain @click="showCookieNotice = false">De acuerdo</el-button>

        </div>
    </div>
</template>

<script>
export default {
    name: 'bottomBar',
    data() {
        return {
            showCookieNotice: true,
        }
    },
    created() {
    },
    methods: {
        navShop(e) {
            if (e == 1) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.prestamo&hl=es-419&gl=co"
            } else {
                window.open('http://confirm.prestamorapido.co/', '_blank')
            }
        },
    }
}
</script>

<style scoped lang="scss">
.bottomBar {
    background: #F8F8FF;
    min-width: 1200px;

}

.body {
    width: 1200px;
    margin: 0 auto;
   
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 0;

    .top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            height: 60px;
        }

        .right {
            .img {
                cursor: pointer;
                height: 66px;
                margin-right: 18px;
                width: 198px;
            }
        }
    }

    .top2 {
        margin-top: 52px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: left;

        .img {
            width: 28px;
            height: 28px;
            display: block;
            margin-right: 38px;
        }
    }

    .top3 {
        margin-top: 38px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .left {
            div {
                margin-bottom: 14px;
                text-align: left;
            }

            span {
                font-weight: 400;
                font-size: 17px;
                color: #282C33;
                margin: 0 8px;
            }

            a {

                font-weight: 600;
                font-size: 17px;
                color: #282C33;
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .item {
                margin: 12px 0;
                display: flex;
                align-items: center;


                .img {
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                }

                .img2 {
                    margin-left: 7px;
                    width: 16px;
                    height: 16px;
                    margin-right: 17px;
                }

                .text {
                    margin-top: 4px;
                    display: flex;
                    flex-direction: column;
                    text-align: left;

                    .text1 {
                        font-weight: 400;
                        font-size: 16px;
                        color: #50545B;
                    }


                }
            }
        }
    }

}

.cookie-notice {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333;
    color: white;
    padding: 15px;
    text-align: center;
    z-index: 66;
    font-size: 13px;
}

.cookie-a {
    color: #fff;
    text-decoration: underline;
    margin: 0 10px;
}
</style>