<template>
    <div class="navigationBar">
        <img src="../assets/logo.png" @click="navTo(1, '/')" class="logo">
        <div class="navs">
            <div class="nav" :class="{ activebg: activeName == 1 }" @click="navTo(1, '/')">
                Inicio
            </div>
            <div class="nav" :class="{ activebg: activeName == 2 }" @click="navTo(2, '/Producto')">
                Producto
            </div>
            <div class="nav" :class="{ activebg: activeName == 3 }" @click="navTo(3, '/Ayuda')">
                Ayuda
            </div>
            
            <div class="nav" :class="{ activebg: activeName == 4 }" @click="navTo(4, '/SobreNosotros')">
                Sobre nosotros
            </div>
            
            <div class="nav" :class="{ activebg: activeName == 5 }" @click="navTo(5, '/Contáctenos')">
                Contáctenos
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'navigationBar',
    props:['activeName'],
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        navTo(e, url) {
            this.$router.push(url);
        }
    }
}
</script>

<style scoped lang="scss">
.navigationBar {
    width: 1200px;
    margin: 0px auto;
    display: flex;
    height: 84px;
    align-items: center;
    justify-content: space-around;
    
    .logo {
        width: 231px;
        height: 36px;
        display: block;
        cursor: pointer;
    }

    .navs {
        display: flex;
        align-items: center;




        .nav {
            margin-left: 57px;
            font-size: 17px;
            font-weight: 600;
            color: #353333;
            text-align: center;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .activebg {
            color: #6765E8 !important;
        }
    }
}
</style>
  