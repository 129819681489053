<template>
    <div class="contnet">
        <navigationBar :activeName="3"></navigationBar>
        <img src="../assets/img/11128.png" class="topimg">
        <div class="div1">
            <div class="collapse">
                <div class="title">  <img src="../assets/img/11135.png" class="titleimg">Solicitud de préstamo</div>
                <div v-for="(i, index) in collapseList1" :kye="i.title">
                    <div class="item" @click="collapseClick(1,index)" >
                        <div class="itemtitle">
                            <div class="left">
                                {{ i.title }}
                            </div>
                            <img src="../assets/img/11122.png" class="right" v-if="i.select" >
                            <img src="../assets/img/11123.png" class="right" v-else >
                            
                        </div>
                        <el-collapse-transition>
                            <div class="itemConten" v-show="i.select">
                                <div class="itemContenyiylr" v-for="j in i.list">
                                    <span v-html="j"></span>
                                </div>
                            </div>
                        </el-collapse-transition>
                    </div>
                </div>
            </div>
            <div class="collapse">
                <div class="title"> <img src="../assets/img/11136.png" class="titleimg">Problemas de préstamo</div>
                <div v-for="(i, index) in collapseList2" :kye="i.title">
                    <div class="item" @click="collapseClick(2,index)" >
                        <div class="itemtitle">
                            <div class="left">
                                {{ i.title }}
                            </div>
                            <img src="../assets/img/11122.png" class="right" v-if="i.select" >
                            <img src="../assets/img/11123.png" class="right" v-else >
                        </div>
                        <el-collapse-transition>
                            <div class="itemConten" v-if="i.select">
                                <div class="itemContenyiylr" v-for="j in i.list">
                                    <span v-html="j"></span>
                                </div>
                            </div>
                        </el-collapse-transition>
                    </div>
                </div>
            </div>
            <div class="collapse">
                <div class="title"> <img src="../assets/img/11137.png" class="titleimg">Problemas de pago</div>
                <div v-for="(i, index) in collapseList3" :kye="i.title">
                    <div class="item" @click="collapseClick(3,index)" >
                        <div class="itemtitle">
                            <div class="left">
                                {{ i.title }}
                            </div>
                            <img src="../assets/img/11122.png" class="right" v-if="i.select" >
                            <img src="../assets/img/11123.png" class="right" v-else >
                        </div>
                        <el-collapse-transition>
                            <div class="itemConten" v-if="i.select">
                                <div class="itemContenyiylr" v-for="j in i.list">
                                    <span v-html="j"></span>
                                </div>
                            </div>
                        </el-collapse-transition>
                    </div>
                </div>
            </div>
            <div class="collapse">
                <div class="title"> <img src="../assets/img/11138.png" class="titleimg">Otros problemas</div>
                <div v-for="(i, index) in collapseList4" :kye="i.title">
                    <div class="item" @click="collapseClick(4,index)" >
                        <div class="itemtitle">
                            <div class="left">
                                {{ i.title }}
                            </div>
                            <img src="../assets/img/11122.png" class="right" v-if="i.select" >
                            <img src="../assets/img/11123.png" class="right" v-else >
                        </div>
                        <el-collapse-transition>
                            <div class="itemConten" v-if="i.select">
                                <div class="itemContenyiylr" v-for="j in i.list">
                                    <span v-html="j"></span>
                                </div>
                            </div>
                        </el-collapse-transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import navigationBar from '@/components/navigationBar.vue'
export default {
    components: {
        navigationBar,

    },
    data() {
        return {
            radio1: null,
            activeNames: null,
            navIndex: 0,
            navList: [
                { name: 'Aplicación', value: 0 },
                { name: 'Préstamos', value: 1 },
                // { name: 'Forma de pago', value: 2 },
                // { name: 'Otros', value: 3 },
            ],
            collapseList1: [{
                    title: 'Requisito indispensable:',
                    select: false,
                    parent:0,
                    list: [
                        'Edad: mayor de 18 años'
                    ]
                },
                {
                    title: 'Trámites indispensables:',
                    select: false,
                    parent:1,
                    list: [
                        '1) Tener su CRUP a la mano',
                        '2) Tener una cuenta de débito de CUALQUIER banco y que esté a SU nombre.',
                        '3) Llenar su solicitud en la aplicación',
                        '<span class="bold">NOTA</span>:Recuerde que no pedimos ninguna clase de anticipos y que la única forma de hacer su solicitud es por medio de la aplicación. TODO EL PROCESO ES GRATUITO.'
                    ]
                },
                {
                    title: '¿Cómo puedo solicitar mi primer préstamo?',
                    select: false,
                    parent:2,
                    list: [
                        'Entre a la app y regístrese con su número telefónico (Le recomendamos crear una contraseña a la vez) y complete los datos básicos. Después de la autorización, de clic en el botón de ¨CONFIRMAR¨, el préstamo llegará a su cuenta bancaria.'
                    ]
                },
                {
                    title: '¿Cómo puedo solicitar de nuevo un préstamo?',
                    select: false,
                    parent:3,
                    list: [
                        'Si le rechazaron su última solicitud, puede volver a solicitar después de 7 días; Una vez que se acredite su pago podrá solicitar un segundo préstamo ¡de inmediato! Y esta vez disfrutará de un monto mayor con un costo menor.'
                    ]
                },
                {
                    title: '¿Cómo tener otro préstamo mientras tengo uno activo?',
                    select: false,
                    parent:4,
                    list: [
                        'Lamentablemente no puede pedir otro préstamo mientras tiene uno activo. Deberá liquidar su préstamo activo para poder solicitar otro. Eso sí, una vez que liquide, podrá solicitar otro de forma inmedia.'
                    ]
                },],
            collapseList2: [ {
                    title: '¿Cuánto tiempo tengo para confirmar mi préstamo en la App?',
                    select: false,
                    parent:5,
                    list: [
                        'Le quedan TRES días para confirmar su préstamo. Le recomendamos dar clic en ¨CONFIRMAR¨ lo antes posible. Cuando se convierta en cliente Premium disfrutará de un monto mayor con un costo menor.'
                    ]
                },                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
                {
                    title: '¿Cuánto tiempo tardan en depositar?',
                    select: false,
                    parent:6,
                    list: [
                        'Procesaremos su solicitud lo antes posible. Una vez aprobada tu solicitud, el depósito se completará en unos minutos. Sin embargo, debido a la diferencia en el horario laboral del Banco de Colombia, puede haber un retraso en su transferencia. No se preocupe, si tiene circunstancias especiales, puede contactarnos al +57 3128182762 de 9:00 a 18:00 en horario laboral o al correo atc. col1620134@gmail.com.'
                    ]
                },
                {
                    title: '¿Qué puedo hacer si falla el depósito?',
                    select: false,
                    parent:7,
                    list: [
                        'Estimado cliente, en este caso puede intentar comprobar si hay un error en el préstamo, o hay un problema con la recepción de su cuenta, y si el estado de su cuenta bancaria es normal. Si tiene circunstancias especiales, puede contactarnos al +57 3128182762 de 9:00 a 18:00 en horario laboral o al correo atc. col1620134@gmail.com. Le proporcionaremos la información más detallada y le brindaremos servicios más personalizados.'
                    ]
                },],
            collapseList3: [{
                    title: '¿Cómo usan la información que lleno en la solicitud de préstamo?',
                    select: false,
                    parent:8,
                    list: [
                        'Préstamo Rápido usa sus respuestas para determinar si es candidato a un préstamo de nuestra app. Toda su información se encuentra segura y protegida. Nunca proporcionaremos su información a terceros sin su permiso. Puede leer nuestro Aviso de Privacidad dentro de la app.'
                    ]
                },
                {
                    title: '¿Cómo pago mi préstamo a SPEI?',
                    select: false,
                    parent:9,
                    list: [
                        '(1) Abre la aplicación de su banco o ingrese a página de internet y seleccione "Transferir";',
                        '(2) Seleccione la cuenta origen;',
                        '(3) Seleccione "Agregar un nuevo destinatario";',
                        '(4) Ingrese el número de cuenta CLABE de Préstamo Rápido;',
                        '(5) Ingrese "Préstamo Rápido" como nombre del Beneficiario y nombre de alias del contracto;',
                        '(6) Seleccione el monto de su pago y reálicelo;',
                        '(7) ¡Transferencia exitosa! Los pagos tardarán hasta 2 horas en reflejarse en Préstamo Rápido, también puede copiar directamente la información de transferencia desde Préstamo Rápido.',
                    ]
                },
                {
                    title: 'Me rechazaron mi préstamo, ¿por qué?',
                    select: false,
                    parent:10,
                    list: [
                        'Lamentamos que su solicitud haya sido rechazada; esto significa que no podrá utilizar nuestros servicios por ahora. ¡No se preocupe! En esta ocasión no fue candidato para un préstamo, pero esperamos que pronto se una a Préstamo Rápido. En cuanto sea elegible de nuevo, la app le mandará una notificación a su celular para realizar una nueva solicitud.'
                    ]
                },
                {
                    title: 'Realicé el pago a tiempo (por adelantado), y me rechazaron mi próximo préstamo, ¿por qué?',
                    select: false,
                    parent:11,
                    list: [
                        'Lamentamos que su solicitud haya sido rechazada. Hay varias razones por las que esto sucede. Para tener una mejor comprensión de lo sucedido, puede contactarnos al +57 3128182762 de 9:00 a 18:00 en horario laboral o al correo atc. col1620134@gmail.com, indicando nombre y telefono registrado para que podamos brindarle un servicio personalizado.'
                    ]
                },
                {
                    title: 'Realicé el pago, sin embargo, en la App no ha disminuido la cantidad pendiente de reembolso. ¿Qué hago?',
                    select: false,
                    parent:12,
                    list: [
                        'Lamentamos que se haya encontrado con este problema. Favor de tener más paciencia, porque los pagos en Oxxo pueden tardar hasta 24 horas en verse reflejados. Y las transferencias interbancarias pueden tardar hasta 72 horas. Si pasado ese tiempo, no ve su pago reflejado, por favor contáctenos al teléfono +57 3128182762 en un horario de 9:00 am a 6:00 pm o por correo atc. col1620134@gmail.com con copia de su comprobante de pago y con gusto le ayudamos.'
                    ]
                },],
            collapseList4: [ {
                    title: 'Accidentalmente pagué en exceso, ¿qué hago?',
                    select: false,
                    parent:13,
                    list: [
                        'No se preocupe, el dinero en exceso se depositará automáticamente en el saldo de su cuenta de Préstamo Rápido y se puede utilizar para el pago.'
                    ]
                },
                {
                    title: 'Me quedo bloqueado al completar la información y no puedo continuar con el siguiente paso. ¿Qué hago?',
                    select: false,
                    parent:14,
                    list: [
                        'Lamentamos que se haya encontrado con este problema. Favor de verificar su red o vuelva a descargar la App para intentar completar su información. Si aún no se resuelve, envíe un correo electrónico a atc. col1620134@gmail.com con copia de su comprobante, le responderemos lo antes posible.'
                    ]
                },
                {
                    title: 'La App me devolvió una interfaz de error, ¿qué hago?',
                    select: false,
                    parent:15,
                    list: [
                        'En este caso, le recomendamos que primero compruebe si la red funciona con normalidad o intente actualizar APP a la última versión. Si el problema persiste, puede contactarnos al +57 3128182762 de 9:00 a 18:00 en horario laboral o al correo atc. col1620134@gmail.com y proporcione la siguiente información: su nombre completo, ID de cliente, descripción de problema y la captura de pantalla que muestra la falla.'
                    ]
                },
                {
                    title: 'Quiero cambiar mi cuenta bancaria, ¿qué hago?',
                    select: false,
                    parent:16,
                    list: [
                        'Si no ha solicitado un préstamo, puede modificar la cuenta bancaria en la página “Yo-Información personal”; si ya ha enviado la solicitud, no necesita cambiar su tarjeta bancaria, puede usar cualquier tarjeta bancaria para realizar el pago; cuando liquide su pedido pendiente, también puede modificar la cuenta bancaria en la página “Yo-Información personal” antes de volver a solicitar un préstamo.'
                    ]
                },
                {
                    title: 'Quiero cambiar mi número de celular, ¿qué hago?',
                    select: false,
                    parent:17,
                    list: [
                        'Si desea cambiar su número de celular cuando está en el paso de registro, puede registrarse con su nuevo número de celular; si le queda un pedido por pagar, puede iniciar la sesión con su número de celular y la contraseña correspondiente; Si tiene un caso particular, por favor contáctenos al teléfono +57 3128182762 en un horario de 9:00 am a 6:00 pm o por correo atc. col1620134@gmail.com'
                    ]
                },
                {
                    title: '¿Cómo puedo aumentar el monto de préstamo?',
                    select: false,
                    parent:18,
                    list: [
                        'Nuestro sistema evalúa el monto según su crédito. Por favor, mantenga un historial crediticio bueno y constante, lo que le ayudará a aumentar el monto de su préstamo. Sin embargo, estos datos se basan en el puntaje integral del sistema y no se permiten factores humanos. Esperamos su comprensión. Si tiene circunstancias especiales, puede contactarnos al +57 3128182762 de 9:00 a 18:00 en horario laboral o al correo atc. col1620134@gmail.com.'
                    ]
                },],

        }
    },
    created() {
    },
    methods: {
        navClick(e) {
            this.navIndex = e
        },
        collapseClick(type,e) {
            if(type == 1){
                this.collapseList1[e].select = !this.collapseList1[e].select
            }
            if(type == 2){
                this.collapseList2[e].select = !this.collapseList2[e].select
            }
            if(type == 3){
                this.collapseList3[e].select = !this.collapseList3[e].select
            }
            if(type == 4){
                this.collapseList4[e].select = !this.collapseList4[e].select
            }
       

        }
    }
}
</script>

<style scoped lang="scss">
.topimg{
    
    width: 100%;
    min-width: 1200px;
}
.div1 {
        width: 1100px;
        margin: 0 auto;
        margin-top: 150px;
        margin-bottom: 150px;
        .collapse {
            margin: 30px 0px;
            .title{
                font-weight: bold;
                font-size: 40px;
                color: #282C33;
                text-align: left;
                display: flex;
                align-items: center;
                .titleimg{
                    width: 75px;
                    height: 75px;
                    margin-right: 12px;
                }
            }
            .item {
                padding: 40px 0;
                width: 100%;
                border-bottom: 1px solid #F0F0F0;

                .itemtitle {
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .left {
                        font-size: 20px;
                        font-weight: 600;
                        color: #353333;
                    }

                    .right {
                        height: 31px;
                        width: 31px;
                    }

                    .xuanzhuan {
                        transform: rotate(180deg)
                    }

                }

                .one {
                    margin-top: 23px;
                }

                .itemContenyiylr {
                    margin-top: 13px;
                    text-align: left;
                    font-size: 16px;
                    font-family: Poppins, Poppins;
                    font-weight: 400;
                    color: #696565;
                    line-height: 30px;
                    transition: 3s;
                }
            }
        }

    }

</style>
  